body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* opentok hardware css */
/* Copied and edited from: https://static.opentok.com/hardware-setup/v1/css/opentok-hardware-setup.css */
.opentok-hardware-setup,
.opentok-hardware-setup * {
    font-size: 100%;
    font-family: Arial, Helvetica, sans-serif;
    vertical-align: baseline;
    text-align: center;
}

.opentok-hardware-setup strong {
  font-weight: bold;
}

.opentok-hardware-setup em {
  font-style: italic;
}

.opentok-hardware-setup {
  box-sizing: border-box;
  border: 0;
  background-color: #333;
  padding: 10px;
  height: 380px;
}

.opentok-hardware-setup .opentok-hardware-setup-camera {
  margin-bottom: 10px;
  padding: 0;
  overflow: auto;
  text-align: right;
}

.opentok-hardware-setup .opentok-hardware-setup-mic {
  margin-bottom: 10px;
  padding: 0;
  overflow: auto;
  text-align: right;
}

.opentok-hardware-setup .opentok-hardware-setup-label {
  color: #ffffff;
  margin-left: 10px;
  padding-top: 1px;
  line-height: 31px;
  text-align: left;
}

.opentok-hardware-setup .opentok-hardware-setup-selector {
  padding: 0;
  margin: 0 0 10px 10px;
  border: 1px solid #9A9A9A;
  overflow: hidden;
  background-color: #fff;
  background: #fff;
  position: relative;
  width: 220px;
}

.opentok-hardware-setup .opentok-hardware-setup-selector:after {
  top: 50%;
  right: 5px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(0, 0, 0, 0);
  border-top-color: #414141;
  border-width: 5px;
  margin-top: -2px;
  z-index: 100;
}

.opentok-hardware-setup .opentok-hardware-setup-selector select::-ms-expand {
   display: none;
}

.opentok-hardware-setup .opentok-hardware-setup-selector select {
  padding: 5px 8px;
  width: 100%;
  color: #414141;
  border: none;
  box-shadow: none;
  background-color: transparent;
  background-image: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  .opentok-hardware-setup .opentok-hardware-setup-selector select {
    padding-right:18px
  }
}

.opentok-hardware-setup .opentok-hardware-setup-preview {
  margin-left: 10px;
  width: 220px;
}


.opentok-hardware-setup .opentok-hardware-setup-camera .opentok-hardware-setup-preview {
  height: 170px;
}

.opentok-hardware-setup .opentok-hardware-setup-activity-bg {
  height: 14px;
  background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D%22-1%200%2010%2014%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20x%3D%220%22%20y%3D%220%22%20width%3D%2210px%22%20height%3D%2214px%22%3E%3Cg%3E%3Crect%20x%3D%220%22%20y%3D%220%22%20width%3D%227%22%20height%3D%2214%22%20fill%3D%22%23ffffff%22%20rx%3D%222.415%22%20ry%3D%222.415%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
}

.opentok-hardware-setup .opentok-hardware-setup-activity-fg {
  height: 14px;
  background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D%22-1%200%2010%2014%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20x%3D%220%22%20y%3D%220%22%20width%3D%2210px%22%20height%3D%2214px%22%3E%3Cdefs%3E%3ClinearGradient%20id%3D%22gradient1%22%20x1%3D%2250%25%22%20y1%3D%220%25%22%20x2%3D%2250%25%22%20y2%3D%22100%25%22%3E%3Cstop%20stop-color%3D%22%23acd24c%22%20stop-opacity%3D%221%22%20offset%3D%220%25%22%2F%3E%3Cstop%20stop-color%3D%22%2397d000%22%20stop-opacity%3D%221%22%20offset%3D%22100%25%22%2F%3E%3C%2FlinearGradient%3E%3C%2Fdefs%3E%3Cg%20id%3D%22Layer%25201%22%3E%3Crect%20x%3D%220%22%20y%3D%220%22%20width%3D%227%22%20height%3D%2214%22%20fill%3D%22url(%23gradient1)%22%20rx%3D%222.415%22%20ry%3D%222.415%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
}


/* opentok layout css */
.layout-container, .publisher-container {
  margin-bottom: 10px;
  height: 100%;
}

.layout-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.layout-container > div {
  background-color: #000;
  display: inline-block;
  border: 1px solid #fff;

  width: 0;
  height: 0;
  opacity: 0;
}

.layout-container > div.ot-layout {
  opacity: 1;
}