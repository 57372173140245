  .fc-event {
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer; 
    background-color: rgb(186, 186, 186);
    border-color: rgb(192, 192, 192);
    color: rgb(89, 89, 89);
  }
  .fc-event:hover {
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer; 
    background-color: gray;
    border-color: gray;
    color:white
  }